import React from 'react';

import './styles.css';

interface SocialNetworkProps {
  link: string;
  icon: string;
}

const SocialNetwork: React.FC<SocialNetworkProps> = ({ link, label, icon }) => {
  return (
    <div className="social-network-container">
      <a 
        href={link}
        target='_blank'
        rel='noopener noreferrer'>
        <img src={icon} alt="Ícone da Rede Social" />
      </a>
    </div>
  );
}

export default SocialNetwork;
