import React, { useContext } from 'react';
import { Link, navigate } from 'gatsby';
import { css } from '@emotion/core';
import { TransitionContext } from './Transition';

export const TransitionLink = props => {
  const { to, children, className } = props;
  const { expanded, setExpanded, setTransition, transition } = useContext(TransitionContext);
  //const { expanded, setExpanded } = useContext(TransitionContext);
  return (
    <Link
      to={to}
      title={to}
      className={className}
      onClick={e => {
        e.preventDefault();
        setTransition(true);
        setExpanded(false);
        navigate(to)
        setTimeout(() => {
          setTransition(false);
        }, 800);
      }}
    >
      {children}
    </Link>
  );
};