import React from 'react'
import { motion, AnimatePresence } from 'framer-motion';
import { useStaticQuery, graphql } from 'gatsby'
import { css } from '@emotion/core';
import Img from 'gatsby-image'
import './transition.css';

const LogoTransition:React.FC = () => {

const arcoVariant = {
  initial:{
    rotate:0,
    transformOrigin:'center center'
  },
  visible: {
    rotate:360,
    transition:{
      rotate:{repeat:Infinity,duration:1.5,ease:'linear'}
    }
  }
};  
  const data = useStaticQuery(query)

  return (
    <AnimatePresence>
      <div className="container-fluid">        
          <div id="logo-transition" className="d-flex align-items-center justify-content-center">
            <motion.div variants={arcoVariant} animate="visible" initial="initial" style={{width:'10rem'}}>
              <Img fluid={data.arco.childImageSharp.fluid}
                className=""
                alt="Teia Anansi logo" />
            </motion.div>
          </div>
      </div>
    </AnimatePresence>
  );
}

export default LogoTransition;

export const query = graphql`
  query transitionImage{
    arco:file(name: {eq: "arco-spider-2"}) {
      publicURL
      childImageSharp{
        fluid{
          ...GatsbyImageSharpFluid
        }
      }
    }
    logo:file(name: {eq: "logo-isolada"}) {
      publicURL
      childImageSharp{
        fluid{
          ...GatsbyImageSharpFluid
        }
      }
    }
  }`