import React from 'react'
import { motion, AnimatePresence } from 'framer-motion';
import { Link } from "gatsby";
import LogoTransition from "./LogoTransition";

export const parentVariants = {
  visible: {
    transition: {
      ease: 'linear',
    },
  },
  hidden: {
    transition: {
      ease:'linear',
    },
  },
};

export const childVariants = {
  initial: {
    opacity: 1,
  },
  animate: {
    opacity: 0,
    transition: {
      type:"tween",
      duration:1,
      // delay:1
    },
  },
}

export const childVariantHead = {

};

export const PageTransition = () =>{
    const {transition, setTransition} = React.useContext(TransitionContext);
    const [playState, setPlayState] = React.useState(transition);

    React.useEffect(() => { 
      setPlayState(transition);
    }, [transition]);

  return (
    <AnimatePresence>
      { playState && (
        <motion.div
          variants={parentVariants}
          initial="initial"
          animate="animate"
          exit="exit"
          aria-hidden={true}
          css={css`
            width: 100%;
            height: 100%;
            position: absolute;
            top: 0;
            left: 0;
            z-index: 1032;
            pointer-events: none;
          `}
        >
          <motion.div variants={childVariants} exit={'visible'} key={1} id="transition-lateral">
            <motion.figure variants={childVariantHead} exit={'visible'} key={2}>
              <LogoTransition/>
            </motion.figure>
          </motion.div>
        </motion.div>
      )}
    </AnimatePresence>
  );
};

export const TransitionContext = React.createContext(undefined);
// export const MenuContext = React.createContext(undefined);

export const TransitionContextProvider = props => {
  const [transition, setTransition] = React.useState(true);
  const [expanded, setExpanded] = React.useState(false);
  return (
    <TransitionContext.Provider
      value={{
        transition: transition,
        setTransition: setTransition,
        expanded:expanded,
        setExpanded:setExpanded
      }}
    >
      {props.children}
    </TransitionContext.Provider>
  );
};
