// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-components-news-index-tsx": () => import("./../../../src/components/News/index.tsx" /* webpackChunkName: "component---src-components-news-index-tsx" */),
  "component---src-components-project-index-tsx": () => import("./../../../src/components/Project/index.tsx" /* webpackChunkName: "component---src-components-project-index-tsx" */),
  "component---src-components-project-midia-tsx": () => import("./../../../src/components/Project/midia.tsx" /* webpackChunkName: "component---src-components-project-midia-tsx" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-index-tsx": () => import("./../../../src/pages/About/index.tsx" /* webpackChunkName: "component---src-pages-about-index-tsx" */),
  "component---src-pages-contact-index-tsx": () => import("./../../../src/pages/Contact/index.tsx" /* webpackChunkName: "component---src-pages-contact-index-tsx" */),
  "component---src-pages-home-index-tsx": () => import("./../../../src/pages/Home/index.tsx" /* webpackChunkName: "component---src-pages-home-index-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-join-index-tsx": () => import("./../../../src/pages/Join/index.tsx" /* webpackChunkName: "component---src-pages-join-index-tsx" */),
  "component---src-pages-news-index-tsx": () => import("./../../../src/pages/News/index.tsx" /* webpackChunkName: "component---src-pages-news-index-tsx" */),
  "component---src-pages-news-slide-show-index-tsx": () => import("./../../../src/pages/News/SlideShow/index.tsx" /* webpackChunkName: "component---src-pages-news-slide-show-index-tsx" */),
  "component---src-pages-photos-index-tsx": () => import("./../../../src/pages/Photos/index.tsx" /* webpackChunkName: "component---src-pages-photos-index-tsx" */),
  "component---src-pages-services-index-tsx": () => import("./../../../src/pages/Services/index.tsx" /* webpackChunkName: "component---src-pages-services-index-tsx" */),
  "component---src-pages-sponsorship-index-tsx": () => import("./../../../src/pages/Sponsorship/index.tsx" /* webpackChunkName: "component---src-pages-sponsorship-index-tsx" */),
  "component---src-pages-team-index-tsx": () => import("./../../../src/pages/Team/index.tsx" /* webpackChunkName: "component---src-pages-team-index-tsx" */),
  "component---src-pages-team-team-member-index-tsx": () => import("./../../../src/pages/Team/TeamMember/index.tsx" /* webpackChunkName: "component---src-pages-team-team-member-index-tsx" */),
  "component---src-pages-timeline-index-tsx": () => import("./../../../src/pages/Timeline/index.tsx" /* webpackChunkName: "component---src-pages-timeline-index-tsx" */)
}

