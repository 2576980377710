import React from 'react';
import { Helmet } from 'react-helmet';
import { useStaticQuery, graphql } from 'gatsby';
import MainNavbar from '../Navbar';

const Header: React.FC = () => {
   // const data = useStaticQuery(query);
   //const title = data.allSite.edges[0].node.siteMetadata.title;
    return (
      <React.Fragment>
        <Helmet title="Coletiva Teia Anansi" />
        <div id="page-header">
          <div className="row">
              <div className="col-md-12">
                  <header>
                    <MainNavbar/>
                  </header>
              </div>
          </div>
        </div>
      </React.Fragment>
    );
}
export default Header;
// export const query = graphql`
//     query {
//       allSite{
//         edges{
//           node{
//             siteMetadata{
//               title
//             }
//           }
//         }
//       }
//     }`


