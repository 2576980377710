/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 * 
 * Transition tutorial reference:
 * https://webanimation.blog/blog/level30wizards-framer-motion-page-transition/#page-transition-component
 */

import React from 'react'
import PropTypes from 'prop-types'
import { ThemeProvider } from 'styled-components'
import { motion } from 'framer-motion'
import { useStaticQuery, graphql } from 'gatsby'

import Header from "../Header"
import Sidebar from "../Sidebar"
import Footer from "../Footer"
import {TransitionContextProvider,PageTransition} from "../Transition/Transition"

import "./layout.css"

const theme = {
  breakpoints: ["40em", "52em", "64em"],
  space: [0, 4, 8, 16, 32, 64, 128, 256],
  fonts: {
    sans: "Roboto, system-ui, sans-serif"
  },
  fontSizes: [12, 14, 16, 20, 24, 32, 46, 54, 62],
};

const Layout: React.FC = ({ children, pageContext }) => {
  if(pageContext.layout !== "404"){
    return (
     <ThemeProvider theme={theme}>
        <TransitionContextProvider>
          <React.Fragment>
            <div className="container-fluid">
              <Header />
                <main>
                  {children}
                </main>
              <Footer />
            </div>
          </React.Fragment>
          <PageTransition/>
        </TransitionContextProvider>
     </ThemeProvider>
    )
  }else{
    return(
      <ThemeProvider theme={theme}>
          <TransitionContextProvider>
            <React.Fragment>
              <div className="container-fluid">
                  <main>
                    {children}
                  </main>
              </div>
            </React.Fragment>
            <PageTransition/>
          </TransitionContextProvider>
       </ThemeProvider>
      )
  }
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
