import React from 'react';
import { Link, navigate } from 'gatsby';
import { NavDropdown, Navbar, Nav } from 'react-bootstrap'
import {TransitionLink} from "../Transition/TransitionLink";
import {TransitionContext} from "../Transition/Transition"

import logo from '../../assets/images/logo-preta.png';

const MainNavbar: React.FC = () => {

  const {expanded,setExpanded,transition, setTransition} = React.useContext(TransitionContext);
  const showTransition = (eventKey,event) =>  {
    event.preventDefault();
    const target = event.target.pathname;
    setTransition(true);
    navigate(target);
    setTimeout(() => {
      setTransition(false);
    }, 800);
  }

  return (
    <Navbar bg="light" collapseOnSelect expand="lg" className="fixed-top align-content-center">
      <div className="container">
        <Navbar.Brand>
          <img
            src={logo}
            width="50"
            height="50"
            className="d-inline-block align-top"
            alt="Teia Anansi logo"
          />
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="mr-auto">
              <Nav.Link href="/" title="Início" className="nav-link" onSelect={showTransition} >Início
              </Nav.Link>
              <Nav.Link  href="/News" className="nav-link" onSelect={showTransition}>
                Notícias
              </Nav.Link>
            <NavDropdown title="Quem somos" className="nav" onSelect={showTransition}>
                <NavDropdown.Item  href="/About" className="dropdown-item">
                  O que é a Teia?
                </NavDropdown.Item>
                <NavDropdown.Item  href="/Team" className="dropdown-item">
                  Equipe
                </NavDropdown.Item>
            </NavDropdown>
            <NavDropdown title="Nosso trabalho" onSelect={showTransition}>
              <NavDropdown.Item  href="/projetos/conheca-nosso-trabalho" className="dropdown-item">
                Teia em ação
              </NavDropdown.Item>
              <NavDropdown.Item  href="/Timeline" className="dropdown-item">
                Teia em movimento
              </NavDropdown.Item>
            </NavDropdown>
            <Nav.Link  href="/Join" className="nav-link" onSelect={showTransition}>
              Junte-se a nós
            </Nav.Link>
            <NavDropdown title="Institucional" onSelect={showTransition}>
              <NavDropdown.Item  href="/Sponsorship" className="dropdown-item">Primeiros apoiadores</NavDropdown.Item>
            </NavDropdown>
          </Nav>
        </Navbar.Collapse> 
      </div> 
    </Navbar>
  );
}

export default MainNavbar;
