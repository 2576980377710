import React from 'react'
import {TransitionLink} from '../Transition/TransitionLink'
import { useStaticQuery, graphql } from 'gatsby'
import Img from 'gatsby-image'

import SocialNetwork from '../SocialNetwork';
import facebookIcon from '../../assets/images/icons/facebook-3.svg'
import instagramIcon from '../../assets/images/icons/instagram-2.svg'
import whatsAppIcon from '../../assets/images/icons/icon_whatsapp.png'

const Footer:React.FC = () => {
  
  const data = useStaticQuery(query)

  return (
    <>
      <footer id="page-footer">
        <div className="container-fluid">
          <div className="row justify-content-center text-center py-5 m-0">
            <div className="col-sm-6 col-md-2 col-xl-2">
              <h3 className="mb-0 md-0 mb-5 bold-text mt-5">TEIA ANANSI</h3>
              <small>
                <span><i className="fa fa-envelope" aria-hidden="true"></i></span>COLETIVA DE MULHERES NEGRAS
              </small>
              <h6 className="mt-5 bold-text"><b>Contato</b></h6>
              <small>
                <span><i className="fa fa-envelope" aria-hidden="true"></i></span>teiaanansi@gmail.com
              </small>
            </div>

            <div className="col-sm-6 col-md-2 col-xl-2">
              <h6 className="mt-5 bold-text"><b>NOSSAS REDES</b></h6>
              <SocialNetwork 
                link="https://www.facebook.com/coletivateia/" 
                icon={facebookIcon}/>
               <SocialNetwork 
                link="https://www.instagram.com/coletivateia" 
                icon={instagramIcon}/>
            </div>

            <div className="col-sm-6 col-md-2 col-xl-2 p-5">
              <Img fixed={data.file.childImageSharp.fixed}
                className="d-inline-block align-top"
                alt="Teia Anansi logo"/>
            </div>

            <div className="col-sm-6 col-md-3 col-xl-2">
              <h6 className="mb-3 mb-lg-4 mt-5 bold-text"><b>MENU</b></h6>
                <ul className="list-unstyled text-dark">
                  <li>
                    <TransitionLink fade to="/" className="">
                      Início
                    </TransitionLink>
                  </li>
                  <li>
                    <TransitionLink fade to="/News" className="">
                      Notícias
                    </TransitionLink>
                  </li>
                  <li>
                    <TransitionLink fade to="/About" className="">
                      Quem Somos
                    </TransitionLink>
                  </li>
                  <li>
                    <TransitionLink fade to="/Team" className="">
                      Equipe
                    </TransitionLink>
                  </li>
                  <li>
                    <TransitionLink fade to="/projetos/conheca-nosso-trabalho" className="">
                      Socioeducativo
                    </TransitionLink>
                  </li>
                  <li>
                    <TransitionLink fade to="/Join" className="">
                      Junte-se a nós
                    </TransitionLink>
                  </li>
                </ul>
            </div>

            <div className="col-sm-6 col-md-2 col-xl-2">
              <h6 className="mb-3 mb-lg-4 bold-text mt-5"><b>ENDEREÇO</b></h6>
              <p>Belo Horizonte, Minas Gerais</p>
            </div>
          </div>
          <div className="row text-center py-5 m-0">
            <div className="col-md-12">
              <small>
                <a href="https://www.linkedin.com/in/hcdias/">Feito com <span className="heart">❤</span> por Hugo Carvalho</a>
              </small>
            </div>
          </div>
        </div>
      </footer>
    </>
  );
}

export default Footer;

export const query = graphql`
  query footerImages{
    file(name: {eq: "logo-clara"}) {
      publicURL
      childImageSharp{
        fixed(width:200){
          ...GatsbyImageSharpFixed
        }
      }
    }
  }`